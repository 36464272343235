<template>
  <div class="airdrop-details">
    <div class="front-row">
      <div class="row-left">
        <div class="Equibirium">
          <img
            :src="
              createdData.logo
                ? createdData.logo
                : require('../../static/images/18.png')
            "
            alt=""
            srcset=""
          />
          <div class="Overview">
            <p class="title">{{ createdData.name }}</p>
            <div class="choice">
              <!--  -->
              <div class="wicrypt-com" @click="windopen(createdData.website)">
                <img src="../../static/images/13.png" alt="" />
                <span> {{ createdData.official }} </span>
              </div>
              <!--  -->
              <el-dropdown
                trigger="click"
                @command="categorysFuntion"
                v-if="createdData.community.length > 0"
              >
                <span class="el-dropdown-link">
                  {{ $t("airdrop.Community")
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="el-dropdown-menu-box">
                  <el-dropdown-item
                    v-for="(item, index) in createdData.community"
                    :key="index"
                    :command="item.url"
                  >
                    <p>{{ item.name }}</p>
                    <img src="../../static/images/15.png" alt="" />
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--  -->
              <div
                class="wicrypt-com left-mar"
                @click="windopen(createdData.whitePaper)"
                v-if="createdData.whitePaper"
              >
                <img src="../../static/images/16.png" alt="" />
                <span>{{ $t("airdrop.Whitepaper") }}</span>
              </div>

              <!--  -->
              <div
                class="wicrypt-com left-mar"
                v-if="createdData.projectId"
                @click="projectId(createdData.projectId)"
              >
                <img src="../../static/images/17.png" alt="" />
                <span>{{ $t("airdrop.Overview") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="participation == 3"
          class="Participate"
          @click="Participate()"
        >
          {{ $t("airdrop.Participate") }}
        </div>
        <div v-if="participation == 2" class="Participate gray">
          {{ $t("airdrop.Starting") }}
        </div>
        <div class="involved-in" v-if="participation == 1">
          <p class="one">{{ $t("airdrop.participated") }}</p>
          <p class="in-two" v-if="$i18n.locale == 'en'">{{ Decemberen }}</p>
          <p class="in-two" v-else>{{ Decemberzh }}</p>
        </div>
        <div
          class="involved-in"
          style="padding: 0 15px"
          v-if="participation == 4"
        >
          <p class="one">
            {{
              $i18n.locale == "en"
                ? "The event has ended, Fomoin will announce the winner list and send airdrop as soon as possible"
                : "本次活动已经结束，Fomoin将尽快公布获奖者名单并进行空投"
            }}
          </p>
        </div>
      </div>
      <!--  -->
      <div class="row-right">
        <p class="title">{{ $t("airdrop.Timeremaining") }}</p>
        <p class="objs"></p>
        <div class="times">
          <div class="DAYSji">
            <div class="HOURS">
              <p class="bos">{{ btnDay(day, 1) }}</p>
              <p class="bos">{{ btnDay(day, 2) }}</p>
            </div>
            <div class="elint">{{ $t("airdrop.DAYS") }}</div>
          </div>
          <div class="DAYSji">
            <div class="HOURS">
              <p class="bos">{{ btnDay(hour, 1) }}</p>
              <p class="bos">{{ btnDay(hour, 2) }}</p>
            </div>
            <div class="elint">{{ $t("airdrop.HOURS") }}</div>
          </div>
          <div class="DAYSji">
            <div class="HOURS">
              <p class="bos">{{ btnDay(min, 1) }}</p>
              <p class="bos">{{ btnDay(min, 2) }}</p>
            </div>
            <div class="elint">{{ $t("airdrop.MINS") }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="mok3">
      <div class="information">
        <div class="title-buttom">
          <p class="mony-title">{{ createdData.title }}</p>
          <div class="Ongoing" v-if="participation == 3">
            {{ $t("airdrop.Ongoing") }}
          </div>
          <div class="Ongoing" v-if="participation == 2">
            {{ $t("airdrop.Upcoming") }}
          </div>
        </div>
        <p class="reminder" v-if="$i18n.locale == 'en'">
          {{
            $i18n.locale == "en"
              ? createdData.airdropStartTimeen
              : createdData.airdropStartTime
          }}
          -
          {{
            $i18n.locale == "en"
              ? createdData.airdropEndTimeen
              : createdData.airdropEndTime
          }}
          , Ends in {{ day }} days
        </p>
        <p class="reminder" v-else>
          {{
            $i18n.locale == "en"
              ? createdData.airdropStartTimeen
              : createdData.airdropStartTime
          }}
          -
          {{
            $i18n.locale == "en"
              ? createdData.airdropEndTimeen
              : createdData.airdropEndTime
          }}
          , {{ day }} 天后结束
        </p>
        <p class="division"></p>
        <div class="Total-Airdrop-Amount">
          <div>{{ $t("airdrop.TotalAirdropAmount") }}</div>
          <p>
            {{ $tool.formatMonenyNumber(createdData.totalAirdropAmount) }}
            {{ createdData.token }}
          </p>
        </div>
        <p class="division"></p>
        <div class="Total-Airdrop-Amount Number-of-Winners">
          <div>{{ $t("airdrop.NumberofWinners") }}</div>
          <p>{{ $tool.formatMonenyNumber(createdData.winnerAddressNumber) }}</p>
        </div>
        <p class="division"></p>
        <div class="Total-Airdrop-Amount Number-of-Winners">
          <div>{{ $t("airdrop.Participants") }}</div>
          <p>{{ $tool.formatMonenyNumber(createdData.participants) }}</p>
        </div>
        <p class="division"></p>
        <div class="How-to-Participate">{{ $t("airdrop.participate") }}</div>
        <div v-html="createdData.content" class="cont-html"></div>
      </div>
      <!--  -->
      <div class="information-right">
        <div
          class="Self-Reported-Tags Reported"
          v-if="createdData.tags.length > 0"
        >
          <p class="Tags">{{ $t("airdrop.SelfReportedTags") }}</p>
          <div class="label-tags astrict">
            <p
              v-for="(item, index) in createdData.tags"
              :key="index"
              v-if="index < 8"
            >
              {{ item }}
            </p>
          </div>
          <p
            class="view-all"
            @click="Viewall()"
            v-if="createdData.tags.length > 8"
          >
            View all
          </p>
        </div>

        <div class="Share" @click="btn_Share">{{ $t("airdrop.Share") }}</div>
        <div class="Airdrops" @click="otherAir()">
          {{ $t("airdrop.CheckOtherAirdrops") }}
        </div>
      </div>
    </div>

    <el-dialog
      title="Sologenic Tags"
      :visible.sync="dialogTableVisible"
      class="Sologenic"
    >
      <div class="Self-Reported-Tags eliminate">
        <p class="Tags title">Self-Reported Tags</p>
        <div class="label-tags">
          <p v-for="(item, index) in createdData.tags" :key="index">
            {{ item }}
          </p>
        </div>
      </div>
    </el-dialog>

    <FaceBook
      v-if="FaceBookIF"
      :dataobj="dataobj"
      @clearfacebook="clearfacebook"
    ></FaceBook>
    <Dropping
      v-if="DroppingIF"
      :dataid="createdData"
      @cleardrop="cleardrop"
      @successclear="successclear"
    ></Dropping>
  </div>
</template>

<script>
import { airdropinfo, isParticipated } from "@/api/require.js";
import { sysnow } from "@/api/controller.js";
import FaceBook from "@/components/facebook";
import Dropping from "@/components/dropping";
export default {
  components: {
    FaceBook,
    Dropping,
  },
  data() {
    return {
      dialogTableVisible: false,
      FaceBookIF: false,
      DroppingIF: false,
      dataobj: {
        project: "",
        des: "",
      },
      Decemberen: "",
      Decemberzh: "",
      createdData: {},
      dataid: 0,
      curStartTime: "",
      day: "0",
      hour: "00",
      min: "00",
      second: "00",
      participation: 3, // 3可以参加， 2还没开始，1已经参加过 4已结束
    };
  },
  created() {
    this.airdropinfo(this.$route.query.id);
    // 用户是否参与空投

    this.Event.$on("data-refresh", (res) => {
      this.airdropinfo(this.$route.query.id);
    });

    // 接受deader的广播
    this.Event.$on("search-project-details", (res) => {
      this.airdropinfo(this.$route.query.id);
    });
  },
  methods: {
    otherAir() {
      this.$store.commit({
        type: "setsetaddress",
        val: "Underway",
      });
      this.$router.push("/airdrop");
    },

    projectId(id) {
      this.$router.push({ path: "/project-details", query: { id: id } });
    },

    successclear() {
      this.cleardrop();
      this.airdropinfo(this.$route.query.id);
    },

    // 用户是否参已报名IDO
    async isParticipated(id) {
      let res = await isParticipated(id);
      if (res.code === 0) {
        if (this.createdData.airdropState == "Finished") {
          this.participation = 4; //已结束
          return false;
        }

        if (!res.data) {
          // 未参加
          let newres = await sysnow();
          if (newres.code === 0) {
            // 未参与情况下判断是否开始
            if (
              new Date(this.$tool.timestampToTime(newres.data, true)) >=
              new Date(this.createdData.airdropStartTime)
            ) {
              // 已开始
              this.participation = 3;
            } else {
              this.participation = 2;
              // 未开始
            }
          }
        } else {
          // 已参与
          this.participation = 1;
        }
      }
    },

    getCurrentTime() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();

      console.log(yy + "/" + mm + "/" + dd + " " + hh + ":" + mf + ":" + ss);
      return yy + "/" + mm + "/" + dd + " " + hh + ":" + mf + ":" + ss;
    },

    cleardrop() {
      this.DroppingIF = false;
    },

    btnDay(day, index) {
      if (index == 1) {
        if (day > 9) {
          return day.toString().substring(0, 1);
        } else {
          return 0;
        }
      } else {
        if (day > 9) {
          return day.toString().substring(1, 2);
        } else {
          if (day.toString().length > 1) {
            return day.toString().substring(1, 2);
          } else {
            return day;
          }
        }
      }
    },

    // 倒计时
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        // 天
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
      } else {
        this.day = 0;
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 2000);
      }
    },

    categorysFuntion(command) {
      console.log(command);
      this.windopen(command);
    },

    windopen(url) {
      if (url) {
        window.open(url);
      }
    },

    async airdropinfo(id) {
      let res = await airdropinfo(id);
      if (res.code === 0) {
        // console.log('把时间格式转为英文')
        res.data["airdropStartTimeen"] = this.$tool.conversion(
          res.data.airdropStartTime,
          1
        );
        res.data["airdropEndTimeen"] = this.$tool.conversion(
          res.data.airdropEndTime,
          1
        );
        this.curStartTime = res.data.airdropEndTime;
        this.countTime();

        this.Decemberen =
          "Come back after" +
          " " +
          this.$tool.conversion(res.data.airdropEndTime, 2) +
          " " +
          "to see if you won";
        this.Decemberzh =
          this.$tool.conversion(res.data.airdropEndTime, 3) +
          "日之后回来看看你是否赢了";

        var date2 = new Date(res.data.airdropStartTime);
        var date1 = new Date(res.data.airdropEndTime);
        var date =
          (date2.getTime() - date1.getTime()) /
          (1000 * 60 * 60 * 24); /*不用考虑闰年否*/
        if (date < 0) {
          res.data["badtime"] = 0;
        } else {
          res.data["badtime"] = date;
        }

        // 解码
        res.data.content = unescape(res.data.content);
        try {
          let a = res.data.website;
          let b = /^http(s)?:\/\/(.*?)\//;
          let s = b.exec(a);
          console.log(s);
          res.data["official"] = b.exec(a)[2];
        } catch (error) {
          res.data["official"] = res.data.website;
        }
        this.createdData = res.data;
        this.dataobj.project = `“I’m participating in the ${res.data.name} token airdrop event on Fomoin. Participating in the event means earning”。`;
        this.isParticipated(this.$route.query.id);
      }
    },

    clearfacebook() {
      this.FaceBookIF = false;
    },

    Participate() {
      // 检查本地是否有token，无toke登陆已经失效
      if (!this.Globaland()) {
        return false;
      }
      this.dataid = this.createdData.id;
      this.DroppingIF = true;
    },

    Globaland() {
      try {
        if (!sessionStorage.getItem("token")) {
          // console.log('广播到index.vue')
          // 广播到index.vue
          this.Event.$emit("landing");
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    btn_Share() {
      this.FaceBookIF = true;
    },

    Viewall() {
      this.dialogTableVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.airdrop-details {
  padding-top: 40px;
  /deep/.Sologenic {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-close:before {
      border: 1px solid #777;
      border-radius: 50%;
    }
    .el-dialog__header {
      .el-dialog__title {
        color: #101010;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .el-dialog {
      border-radius: 20px;
      margin: 0 !important;
    }
  }
  .mok3 {
    display: flex;
    margin-top: 10px;
    .information {
      padding: 20px;
      box-sizing: border-box;
      width: 80%;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 19px;
      .Total-Airdrop-Amount {
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          font-size: 12px;
          // color: #96a4d1;
        }
        p {
          font-size: 14px;
          color: #1edfa6;
        }
      }
      .Number-of-Winners {
        margin-top: 12px;
      }
      /deep/.cont-html {
        margin-top: 22px;
        font-size: 14px;
        line-height: 1.5;
        // color: #96a4d1;
        word-break: break-word;
        a {
          background: initial !important;
          color: #20cda2 !important;
        }
      }
      .How-to-Participate {
        font-weight: bold;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin: 20px 0;
      }
      .division {
        margin-top: 10px;
        height: 1px;
        background: linear-gradient(0deg, #2d3138, #3a3f45);
      }
      .reminder {
        margin-top: 10px;
        font-size: 12px;
        // color: #96a4d1;
      }
      .title-buttom {
        display: flex;
        align-items: center;
        .mony-title {
          font-size: 18px;
          color: #ffffff;
        }
        .Ongoing {
          margin-left: 16px;
          width: 75px;
          padding: 3px 5px;
          font-size: 14px;
          color: #13154d;
          background: #1edfa6;
          border-radius: 10px;
          text-align: center;
        }
      }
    }
    // ------
    .information-right {
      width: 250px;
      margin-left: 10px;
      .Share {
        height: 32px;
        border: 1px solid #20cda2;
        border-radius: 16px;
        font-size: 14px;
        color: #20cda2;
        text-align: center;
        line-height: 32px;
        margin-top: 22px;
        cursor: pointer;
      }
      .Airdrops {
        margin-top: 10px;
        height: 32px;
        background: linear-gradient(-37deg, #1edfa6, #41ffc7);
        border-radius: 16px;
        font-size: 14px;
        color: #13154d;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .Self-Reported-Tags {
    padding: 20px 20px 40px;
    box-sizing: border-box;
    min-height: 174px;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    border-radius: 19px;
    position: relative;
    .view-all {
      cursor: pointer;
      text-align: right;
      color: #00df85;
      font-size: 18px;
      position: absolute;
      right: 13px;
      bottom: 8px;
    }
    .label-tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin: 5px;
        height: 22px;
        background: linear-gradient(-37deg, #1edfa6, #41ffc7);
        border-radius: 11px;
        font-size: 14px;
        color: #080808;
        padding: 0 12px;
        box-sizing: border-box;
        text-align: center;
        line-height: 22px;
      }
    }
    .astrict {
      overflow: hidden;
      cursor: pointer;
    }
    .Tags {
      font-size: 14px;
      color: #20cda2;
      margin-bottom: 17px;
    }
  }
  .Reported {
    overflow: hidden;
  }
  .eliminate {
    .title {
      color: #757373;
    }
    padding: 0;
    background-color: #ffffff;
  }
  .front-row {
    display: flex;
    align-items: center;
    .row-left {
      overflow: hidden;
      width: 80%;
      height: 170px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      box-sizing: border-box;
      .Equibirium {
        display: flex;
        align-items: center;
        img {
          width: 54px;
          height: 54px;
          border-radius: 50%;
        }
        /deep/.Overview {
          margin-left: 15px;
          .title {
            font-size: 16px;
            color: #ffffff;
          }
          .choice {
            display: flex;
            align-items: center;
            margin-top: 15px;
            .el-dropdown {
              margin-left: 10px;
              height: 20px;
              padding: 0 10px;
              box-sizing: border-box;
              background: rgba(#1edfa6, 0.1);
              border-radius: 10px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .el-dropdown-link {
              cursor: pointer;
              color: #1edfa6;
              white-space: nowrap;
              display: flex;
              align-content: center;
              img {
                width: 12px;
                height: 12px;
              }
            }
            .el-icon-arrow-down {
              font-size: 12px;
            }
            .demonstration {
              display: block;
              color: #8492a6;
              font-size: 14px;
              margin-bottom: 20px;
            }
            .left-mar {
              margin-left: 10px;
            }
            .wicrypt-com {
              min-width: 90px;
              padding: 0 8px;
              cursor: pointer;
              height: 20px;
              background: rgba(#1edfa6, 0.1);
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                font-size: 12px;
                color: #1edfa6;
              }
              img {
                width: 10px;
                height: 10px;
                margin-right: 5px;
              }
            }
          }
        }
      }
      .involved-in {
        margin-left: 20px;
        width: 344px;
        height: 64px;
        overflow: hidden;
        text-align: center;
        background: #52565d;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .one {
          font-size: 16px;
          color: #ffffff;
        }
        .in-two {
          font-size: 12px;
          color: #ffffff;
        }
      }
      .Participate {
        width: 126px;
        height: 32px;
        background: linear-gradient(-37deg, #1edfa6, #41ffc7);
        border-radius: 16px;
        font-size: 16px;
        color: #13154d;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
      .gray {
        background: #3a3f5c;
        color: #ffffff;
        font-size: 16px;
      }
    }
    .row-right {
      z-index: 99;
      margin-left: 10px;
      width: 250px;
      height: 130px;
      background: linear-gradient(-37deg, #1edfa6, #41ffc7);
      border-radius: 19px;
      padding: 15px 0;
      box-sizing: border-box;
      .title {
        padding-left: 15px;
        font-size: 16px;
        color: #13154d;
      }
      .objs {
        height: 0.5px;
        background: #1ca27a;
        opacity: 0.26;
        margin: 11px 0;
      }
      .times {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        .DAYSji {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          .elint {
            font-size: 12px;
            font-weight: bold;
            color: #1a9c76;
            margin-top: 10px;
            box-sizing: border-box;
          }
          .HOURS {
            display: flex;
            align-items: center;

            .bos {
              background: url("../../static/images/49dcef02fa9bf5dbb6ba499e1e14b3f.png")
                100% center/100% no-repeat;
              box-shadow: -2px 13px 10px #24deaa;
              border-radius: 5px;
              width: 25px;
              height: 40px;
              text-align: center;
              line-height: 40px;
              color: #13154d;
              font-size: 17px;
              font-weight: bold;
              margin: 0 2px;
            }
          }
        }
      }
    }
  }
}

.isMobile .airdrop-details {
  padding: 0;

  .front-row {
    flex-wrap: wrap;
    .row-left {
      width: 100%;
      margin: 0;
      flex-wrap: wrap;

      .Equibirium {
        width: 100%;
      }

      .involved-in {
        margin: 10px 0 0 0;
      }
    }
    .row-right {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
  .mok3 {
    flex-wrap: wrap;

    .information {
      width: 100%;
    }

    .information-right {
      width: 100%;
      margin: 10px 0 0 0;
    }
  }
  .Self-Reported-Tags {
    min-height: initial;
  }
  .dropping .el-dialog__wrapper .el-dialog {
    width: 100%;
  }
}
</style>
