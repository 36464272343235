<template>
  <div class="dropping">
    <el-dialog
      :title="dataid.title"
      :visible.sync="dialogTableVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        v-for="(i, index) in conditionList"
        :class="['list-box', index % 2 != 0 ? 'whiteness' : '']"
        :key="i.id"
      >
        <div class="datum" v-if="i.box">
          <div class="p">
            <p>{{ $t("airdropForms.desc1") }}</p>
            <p style="color: #e61010; font-size: 14px" v-if="watchlist">
              {{ $t("airdropForms.desc2") }}
            </p>
          </div>
          <div
            v-if="focuson == 'Unfollow'"
            class="go"
            @click="airdropfollow(focuson)"
          >
            {{ $t("airdropForms.follow") }}
          </div>
          <div v-else class="go focuson">√</div>
        </div>
        <div class="datum" v-if="i.box1">
          <p class="p">{{ i.box1.description }}</p>
          <div class="go" @click="widnopen(i.box1.link)">GO</div>
        </div>
        <div class="datum" v-if="i.box2">
          <p class="p">{{ i.box2.description }}</p>
          <div class="go" @click="widnopen(i.box2.link)">GO</div>
        </div>
      </div>
      <div class="headline" style="margin-top: 40px">
        <!-- <p class="chieftain">{{dataid.title}}</p> -->
        <p class="akela">
          {{ $t("airdropForms.tips") }}
        </p>
      </div>
      <div style="padding: 0 20px">
        <p class="form-item">{{ $t("airdropForms.WalletAddress") }}</p>
        <el-input
          @blur="blurEthereum"
          class="inline-input"
          :placeholder="$t('airdropForms.WalletAddressPlaceholder')"
          v-model.trim="Ethereum"
        ></el-input>
        <p v-if="valueEthereum" style="font-size: 14px; color: #cc0909">
          {{ $t("airdropForms.AddressRequired") }}
        </p>
      </div>

      <div
        style="padding: 0 20px"
        v-for="(item, index) in questionList"
        :key="index"
      >
        <p class="form-item">
          {{ item.socialWebsite }} {{ $t("airdropForms.handle") }}
        </p>
        <el-autocomplete
          @blur="blurTwitter(item)"
          class="inline-input"
          :placeholder="`${$t('airdropForms.pleaseFill')}${
            item.socialWebsite
          } ${$t('airdropForms.handle')}...`"
          v-model.trim="item.value"
          :fetch-suggestions="
            (queryString, cb) => {
              querySearchAsync(queryString, cb, item);
            }
          "
          @select="handleSelectTwitter(item)"
        ></el-autocomplete>
        <p v-if="item.valueTwitter" style="font-size: 14px; color: #cc0909">
          {{ $t("airdropForms.pleaseFill") }} {{ item.socialWebsite }}
          {{ $t("airdropForms.handle") }}!
        </p>
      </div>
      <!--  -->
      <div style="padding: 0 20px">
        <div class="understand">
          <div>
            <p class="">
              {{ $t("airdropForms.agree1") }}
            </p>
            <span v-if="valueswitch1" style="font-size: 14px; color: #cc0909">{{
              $t("airdropForms.agreeError")
            }}</span>
          </div>
          <el-switch v-model="switch1" @change="changeswitch(1)"></el-switch>
        </div>
        <div class="understand">
          <div>
            <p class="">
              {{ $t("airdropForms.agree2") }}
            </p>
            <span v-if="valueswitch2" style="font-size: 14px; color: #cc0909">{{
              $t("airdropForms.agreeError")
            }}</span>
          </div>
          <el-switch v-model="switch2" @change="changeswitch(2)"></el-switch>
        </div>
        <div class="understand">
          <div>
            <p class="">
              {{ $t("airdropForms.agree3") }}
            </p>
            <span v-if="valueswitch3" style="font-size: 14px; color: #cc0909">{{
              $t("airdropForms.agreeError")
            }}</span>
          </div>
          <el-switch v-model="switch3" @change="changeswitch(3)"></el-switch>
        </div>
      </div>
      <!--  -->
      <div class="Jion-Airdrop" @click="present()">
        {{ $t("airdropForms.JoinAirdrop") }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  airdropparticipate,
  airdropfollow,
  isFollow,
  airdropsubmit,
} from "@/api/require.js";
export default {
  props: ["dataid"],
  data() {
    return {
      dialogTableVisible: true,
      restaurants_Twitter: [],
      Ethereum: this.$store.state.address,
      valueTwitter: false,
      valueEthereum: false,
      switch1: false,
      switch2: false,
      switch3: false,
      valueswitch1: false,
      valueswitch2: false,
      valueswitch3: false,

      conditionList: [],
      questionList: [],
      watchlist: false,
      focuson: "Follow",

      list: [
        {
          airdropId: this.dataid.id,
          answer: this.Ethereum,
          socialWebsite: "Address",
          userAddress: this.$store.state.address,
        },
      ],
      dataPAEADROP: [],
    };
  },
  created() {
    this.airdropparticipate();

    // 是否关注过
    this.isFollow();
  },
  mounted() {},

  methods: {
    async isFollow() {
      let res = await isFollow(this.dataid.id);
      if (res.code == 0) {
        this.focuson = res.data;
      }
      console.log(res, "是否关注过");
    },

    async airdropfollow(en) {
      let obj = {
        airdropId: this.dataid.id,
        followState: "Follow",
      };
      if (en == "Follow") {
        // 取关
        obj.followState = " Unfollow";
      } else {
        // 关注
        obj.followState = "Follow";
      }
      let res = await airdropfollow(obj);
      console.log(res);
      if (res.code === 0) {
        this.watchlist = false;
        this.focuson = "Follow";
        this.isFollow();
      }
    },

    widnopen(link) {
      window.open(link);
    },

    async airdropparticipate() {
      let res = await airdropparticipate(this.dataid.id);

      if (res.data.conditionList.length > 0) {
        let array = [];
        for (
          let i = 0;
          i < Math.floor(res.data.conditionList.length / 2) + 1;
          i++
        ) {
          let obj = {};
          if (i == 0) {
            obj["box"] = {};
            obj["box2"] = res.data.conditionList[i];
          } else {
            if (res.data.conditionList[i + (i - 1)]) {
              obj["box1"] = res.data.conditionList[i + (i - 1)];
            }
            if (res.data.conditionList[i + i]) {
              obj["box2"] = res.data.conditionList[i + i];
            }
          }
          array.push(obj);
        }

        this.conditionList = array;
      } else {
        console.log("jinq-----------");
        this.conditionList = [{ box: true }];
      }

      res.data.questionList.forEach((item) => {
        item["valueTwitter"] = false;
        item["value"] = item.answer;
      });
      this.questionList = res.data.questionList;
    },
    handleClose() {
      this.$emit("cleardrop");
    },
    changeswitch(num) {
      if (num == 1) {
        if (!this.switch1) {
          this.valueswitch1 = true;
        } else {
          this.valueswitch1 = false;
        }
      } else if (num == 2) {
        if (!this.switch2) {
          this.valueswitch2 = true;
        } else {
          this.valueswitch2 = false;
        }
      } else {
        if (!this.switch3) {
          this.valueswitch3 = true;
        } else {
          this.valueswitch3 = false;
        }
      }
    },
    blurEthereum() {
      if (!this.Ethereum) {
        this.valueEthereum = true;
      } else {
        this.valueEthereum = false;
      }
    },
    blurTwitter(val) {
      console.log(val);
      if (!val.value) {
        val.valueTwitter = true;
      } else {
        val.valueTwitter = false;
      }
    },

    async present() {
      let state = true;

      if (this.focuson == "Unfollow") {
        state = false;
        this.watchlist = true;
      } else {
        this.watchlist = false;
      }

      // 判断必填项，必选项都选好没
      this.questionList.forEach((item) => {
        if (item.value) {
          item.valueTwitter = false;
        } else {
          state = false;
          item.valueTwitter = true;
        }
      });

      if (!this.switch1) {
        state = false;
        this.valueswitch1 = true;
      } else {
        this.valueswitch1 = false;
      }
      if (!this.switch2) {
        state = false;
        this.valueswitch2 = true;
      } else {
        this.valueswitch2 = false;
      }
      if (!this.switch3) {
        state = false;
        this.valueswitch3 = true;
      } else {
        this.valueswitch3 = false;
      }

      if (state) {
        // 获取到当前用户的标识id
        let ID = this.$store.state.address;
        // 获取本地存储
        let stateData = localStorage.getItem("PAEADROP");
        if (stateData) {
          let finger = false;
          let items,
            num = null;
          stateData = JSON.parse(stateData);
          stateData.forEach((item, index) => {
            if (item.key == ID) {
              items = item;
              num = index;
              finger = true;
            }
          });
          if (finger) {
            // 找到对应的key
            this.requires("amend", items, num);
          } else {
            this.requires("add");
          }
        } else {
          this.requires();
        }
      }
    },

    async requires(flager, data, index) {
      try {
        if (flager != "amend") {
          let objdata = {
            key: this.$store.state.address,
          };
          this.questionList.forEach((eleme) => {
            console.log(eleme, "=====");
            // 创建存储数据
            let array = [];
            array.push({ value: eleme.value });
            objdata[eleme.socialWebsite] = array;

            // 发送后端数据
            let obj = {
              airdropId: this.dataid.id,
              answer: eleme.value,
              socialWebsite: eleme.socialWebsite,
              userAddress: this.$store.state.address,
            };

            this.list.push(obj);
          });

          let res = await airdropsubmit(this.list);
          if (res.code === 0) {
            this.$emit("successclear");
          } else {
            this.$message.error(res.msg);
          }

          // 在原有的基础上 push 一条新的数据
          if (flager == "add") {
            console.log("aaaaaaaaaaa");

            let addData = localStorage.getItem("PAEADROP");
            addData = JSON.parse(addData);

            addData.push(JSON.parse(JSON.stringify(objdata)));

            console.log(addData, "bbbbbbbbbbbbb");
            // 保存
            localStorage.setItem("PAEADROP", JSON.stringify(addData));

            console.log(
              JSON.parse(localStorage.getItem("PAEADROP")),
              "cccccccccc"
            );
          } else {
            // 第一次添加
            this.dataPAEADROP.push(objdata);
            let data = JSON.parse(JSON.stringify(this.dataPAEADROP));
            // 缓存到本地
            localStorage.setItem("PAEADROP", JSON.stringify(data));
            console.log(data, "要缓存的数据");
          }
        } else {
          // flager === 'amend'
          let amenddata = localStorage.getItem("PAEADROP");
          amenddata = JSON.parse(amenddata);
          this.questionList.forEach((item) => {
            // 发送后端数据
            let obj = {
              airdropId: this.dataid.id,
              answer: item.value,
              socialWebsite: item.socialWebsite,
              userAddress: this.$store.state.address,
            };
            this.list.push(obj);
            // 当前数据对象中是否存在对于的 key
            if (data[item.socialWebsite]) {
              console.log(data[item.socialWebsite], 1111111111);
              // 有
              let array = data[item.socialWebsite];
              array.push({ value: item.value });

              var arr1 = array.filter(function (element, index, self) {
                return (
                  self.findIndex((el) => el.value == element.value) === index
                );
              });

              // 去重后的
              data[item.socialWebsite] = arr1;
            } else {
              console.log(222222222);
              // 没有当前key
              let array = [];
              array.push({ value: item.value });
              data[item.socialWebsite] = array;
            }
          });
          console.log(data, 333333333);

          let res = await airdropsubmit(this.list);
          if (res.code === 0) {
            this.$emit("successclear");
          } else {
            this.$message.error(res.msg);
          }

          // 替换数组中的某一项

          console.log(amenddata, 44444444);
          amenddata[index] = JSON.parse(JSON.stringify(data));

          console.log(amenddata[index], 55555555555555);
          console.log(amenddata, 66666666666);

          localStorage.setItem("PAEADROP", JSON.stringify(amenddata));
        }
      } catch (error) {
        console.log(error);
      }
    },

    querySearchAsync(queryString, cb, item) {
      var restaurants = [];

      console.log("输入的内容", queryString);
      console.log("输入框k值", item.socialWebsite);
      // 更具当前用户标识去缓存库中查找当前输入框存储的所有缓存
      // 1、拿到当前用户标识

      try {
        let ID = this.$store.state.address;
        console.log(ID, "用户标识");
        // 2、获取本地的缓存
        let stateData = localStorage.getItem("PAEADROP");
        console.log(JSON.parse(stateData), "111111111");
        // 3、当前输入框的标识 item.socialWebsite
        if (stateData) {
          // 本地有缓存
          // 在缓存里面查找当前标识是否有缓存
          // 在当前标识缓存里面查找是否有当前输入框的缓存
          stateData = JSON.parse(stateData);
          let status = false;
          let stmygv = null;
          stateData.forEach((element) => {
            if (ID == element.key) {
              stmygv = element;
              status = true;
            }
          });
          if (status) {
            // 找到了
            if (stmygv[item.socialWebsite]) {
              // 找到了有当前input的缓存
              // 1 检查是否为空
              if (stmygv[item.socialWebsite].length > 0) {
                restaurants = stmygv[item.socialWebsite];
              } else {
                // 没有数据
                restaurants = [];
              }
            } else {
              // 没找到
              restaurants = [];
            }
          } else {
            restaurants = [];
          }
        } else {
          // 连个毛都没找到
        }
        var results = queryString
          ? restaurants.filter(this.createFilter(queryString))
          : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      } catch (error) {
        console.log(error);
      }
    },

    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    },
    handleSelectTwitter(item) {
      item.valueTwitter = false;

      console.log(item);
    },
    handleSelectEthereum() {
      this.valueEthereum = false;
    },
  },
};
</script>

<style lang="less" scoped>
.dropping {
  /deep/.el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-dialog {
      width: 770px;
      max-height: 800px;
      border-radius: 20px;
      margin: 0 auto !important;
      box-shadow: 0px 3px 49px 0px rgba(17, 18, 21, 0.4);
      background: #1e2025;
      .el-icon-close:before {
        border: 1px solid #777;
        border-radius: 50%;
      }
      .el-input__inner {
        background: transparent;
        border-color: #333439;
        color: #fefefe;

        &:hover,
        &:active {
          border-color: #f0f0f0;
        }

        &::-webkit-input-placeholder {
          border-color: #fefefe;
          color: #606266;
        }
      }

      .el-dialog__title {
        color: #f0f0f0;
      }
      .el-dialog__body {
        max-height: 706px;
        overflow: auto;
        padding: 0;
        padding-bottom: 20px;
        .Jion-Airdrop {
          cursor: pointer;
          width: calc(100% - 40px);
          height: 43px;
          background: #20cda2;
          border-radius: 5px;
          font-size: 14px;
          color: #ffffff;
          margin: 20px auto 0;
          text-align: center;
          line-height: 43px;
        }
        .el-switch.is-checked .el-switch__core {
          border-color: #20cda2;
          background-color: #20cda2;
        }
        .understand {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          p {
            width: 600px;
            font-size: 12px;
            color: #9f9f9f;
          }
        }
        .el-autocomplete {
          width: 100%;
        }
        .form-item {
          font-size: 14px;
          color: #848891;
          margin: 20px 0 10px;
          box-sizing: border-box;
        }
        .list-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // background: #f5f5f5;
          padding: 0 20px;
          box-sizing: border-box;
          // height: 55px;
          .datum {
            width: 340px;
            font-size: 14px;
            color: #2a2a2a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0;
            .p {
              width: 240px;
              color: #848891;
            }
            .go {
              cursor: pointer;
              width: 62px;
              height: 28px;
              background: #20cda2;
              border-radius: 14px;
              font-size: 14px;
              color: #ffffff;
              text-align: center;
              line-height: 28px;
            }
          }
        }
        .whiteness {
          // background: #ffffff;
        }
        .headline {
          padding: 0 20px;
          box-sizing: border-box;
          margin-bottom: 12px;
          .chieftain {
            font-size: 18px;
            font-weight: bold;
            color: #848891;
          }
          .akela {
            // font-size: 12px;
            color: #9f9f9f;
          }
        }

        &::-webkit-scrollbar {
          width: 3px;
          background: #fff;
          border-radius: 20px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #26e7ae;
        }
      }
    }
  }
}
.isMobile {
  .dropping {
    /deep/.el-dialog__wrapper {
      .el-dialog {
        width: 100%;
      }

      .el-dialog__header {
        padding: 20px 30px 10px;
      }

      .list-box {
        flex-wrap: wrap;
      }

      .list-box .datum {
        padding: 10px 0;
      }

      .el-dialog__body .understand {
        flex-wrap: wrap;
        p {
          width: 100%;
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
